/* eslint-disable react/prop-types */
import React from 'react';

export function MenuIcon(props) {
  const { textColor } = props;
  return (
    <svg
      id="prefix__\u0421\u043B\u043E\u0439_1"
      viewBox="0 0 139 139"
      xmlSpace="preserve"
    >
      <path
        stroke={textColor}
        className="prefix__st0"
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
        id="prefix__XMLID_6_"
        d="M26.5 46.3h86"
      />
      <path
        stroke={textColor}
        className="prefix__st0"
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
        id="prefix__XMLID_9_"
        d="M26.5 92.7h86"
      />
      <path
        stroke={textColor}
        className="prefix__st0"
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
        id="prefix__XMLID_8_"
        d="M26.5 69.5h86"
      />
    </svg>
  );
}
