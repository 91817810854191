import React from 'react';
import { string } from 'prop-types';

export function MLBLogo({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      height={92.56}
      width={171.691}
      viewBox="0 0 171.691 92.56"
    >
      <path
        d="M157.403 0H14.315A14.421 14.421 0 004.17 4.128 14.26 14.26 0 00.001 14.341v63.851a14.153 14.153 0 004.155 10.173 14.328 14.328 0 0010.16 4.195h143.087a14.354 14.354 0 0014.288-14.368V14.328A14.22 14.22 0 00157.578 0h-.175z"
        fill="none"
      />
      <path
        d="M166.665 14.328a9.194 9.194 0 00-9.086-9.301h-40.17l22.946 38.345 2.118.268 1.34 1.755v1.636l1.448.294 1.34 1.823v1.582l1.501.268 1.514 1.648v3.646a24.85 24.85 0 006.032 4.02c2.024.778 2.251 4.022 3.471 5.75 1.528 2.52 3.619 3.526 3.177 4.933-1.02 3.766-4.88 10.119-8.471 10.414h-14.207v6.085h17.799a9.302 9.302 0 009.262-9.342V14.328"
        fill=""
      />
      <path
        d="M68.824 81.503H61.95c0-17.183 5.803-26.685 12.76-28.575.95-.174.495-4.865-.711-6.3h-4.021c-.643 0-.268-1.206-.268-1.206l3.257-6.956-.443-1.903H60.488l9.891-6.916c.456-18.241 19.166-19.662 30.438-12.062 6.701 4.436 7.21 13.228 6.701 19.3-.08.388-1.742.134-1.742.134s-1.14 6.701 1.836 6.701h13.148c5.361-.214 10.535 3.418 10.535 3.418l1.26-4.597-28.803-37.515H14.315a9.382 9.382 0 00-6.621 2.68 9.288 9.288 0 00-2.68 6.622v63.864a9.167 9.167 0 002.68 6.594 9.382 9.382 0 006.621 2.748h58.061a681.38 681.38 0 00-3.538-6.031"
        fill=""
      />
      <path
        d="M17.907 67.952a6.353 6.353 0 116.353 6.354h-.054a6.286 6.286 0 01-6.3-6.273l.001-.08"
        fill="none"
      />
    </svg>
  );
}

MLBLogo.propTypes = {
  modifier: string,
};
