/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'gatsby';
// components
import { CloseIcon, MenuIcon } from './svgs';
// data
import { navigation_old as navigation } from '../data/navigation.json';

function Navbar(props) {
  const { handleNavState, isOpen, textColor } = props;

  return (
    <>
      <nav className="navbar" role="navigation">
        <div
          className={
            isOpen ? 'navbar__container' : 'navbar__container is-hidden'
          }
        >
          {navigation.map(({ text, route }) => (
              text == "films" ? <a class="navbar__link" target="_blank" href="http://films.falkoncontent.com/">films</a> : <Link className="navbar__link" to={route}>{text}</Link>

          ))}
        </div>
        <button
          type="button"
          className="navbar__button"
          onClick={handleNavState}
        >
          {isOpen ? (
            <CloseIcon textColor={textColor} className="navbar__button-icon" />
          ) : (
            <MenuIcon textColor={textColor} className="navbar__button-icon" />
          )}
        </button>
      </nav>
    </>
  );
}

export default Navbar;
