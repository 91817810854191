/* eslint-disable */
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { TimelineMax, Power3 } from 'gsap';

const swipeTopDirection = (direction, reverse) => {
  const polarityPos = reverse ? '-' : '+';
  const polarityNeg = reverse ? '+' : '-';
  switch (direction) {
    case 'down':
      return { y: `${polarityPos}=100vh`, ease: Power3.easeInOut };
    case 'up':
      return { y: `${polarityNeg}=100vh`, ease: Power3.easeInOut };
    case 'left':
      return { x: `${polarityNeg}=100%`, ease: Power3.easeInOut };
    default:
      return { x: `${polarityPos}=100%`, ease: Power3.easeInOut };
  }
};

const swipeBottomDirection = (direction, reverse = false, offset = 40) => {
  const polarityPos = reverse ? '-' : '';
  const polarityNeg = reverse ? '' : '-';
  switch (direction) {
    case 'down':
      return { y: `${polarityNeg}${offset}vh`, ease: Power3.easeInOut };
    case 'up':
      return { y: `${polarityPos}${offset}vh`, ease: Power3.easeInOut };
    case 'left':
      return { x: `${polarityPos}${offset}%`, ease: Power3.easeInOut };
    default:
      return { x: `${polarityNeg}${offset}%`, ease: Power3.easeInOut };
  }
};

const swipe = ({ node, exit, direction, top, triggerName, entryOffset }) => {
  const scrollTop =
    (document.scrollingElement && document.scrollingElement.scrollTop) ||
    document.body.scrollTop ||
    window.pageYOffset;
  const tlWrapper = document.querySelector('.tl-wrapper');

  let swipeTimeLine;
  if (triggerName === 'entry' && top === 'entry') {
    swipeTimeLine = new TimelineMax()
      .set(node, {
        overflowY: 'hidden',
        height: '100vh',
        scrollTop,
      })
      .from(node, exit.length, swipeTopDirection(direction, true))
      .set(node, {
        overflowY: 'initial'
      });
  } else if (triggerName === 'entry') {
    // === ON ENTRY ===
    swipeTimeLine = new TimelineMax().from(
      node,
      exit.length,
      swipeBottomDirection(direction, false, entryOffset)
    );
  } else if (triggerName === 'exit' && top === 'exit') {
    const currBgColor = tlWrapper
    ? tlWrapper.firstElementChild.style.backgroundColor
    : '';

    // === ON EXIT ===
    swipeTimeLine = new TimelineMax()
      .set(node, {
        overflowY: 'hidden',
        height: '100vh',
        scrollTop,
      })
      .to(node, exit.length, swipeTopDirection(direction))
      .set(node, {
        overflowY: 'initial',
        opacity: '0'
      });
  } else {
    swipeTimeLine = new TimelineMax()
      .set(node, {
        overflowY: 'hidden',
        height: '100vh',
        scrollTop,
      })
      .to(node, exit.length, swipeBottomDirection(direction, true, entryOffset))
      .set(node, {
        overflowY: 'initial'
      });
  }
  return swipeTimeLine;
};

export default function SwipeOver({
  exit,
  entry,
  swipe: removedProp,
  entryOffset = 40,
  ...props
}) {
  const top = props.top || 'exit';
  const exitLength = props.duration || 0.7;
  const entryLength = exitLength / 3.5;
  const entryZ = top === 'entry' ? 1 : 0;
  const exitZ = top === 'exit' ? 1 : 0;

  return (
    <TransitionLink
      exit={{
        length: exitLength,
        trigger: ({ node, exit }) =>
          swipe({
            node,
            exit,
            direction: props.direction,
            top,
            entryOffset,
            triggerName: 'exit',
          }),
        zIndex: exitZ,
      }}
      entry={{
        length: entryLength,
        trigger: ({ node, exit }) =>
          swipe({
            node,
            exit,
            direction: props.direction,
            top,
            entryOffset,
            triggerName: 'entry',
          }),
        zIndex: entryZ,
      }}
      {...props}
    >
      {props.children}
    </TransitionLink>
  );
}
