import React from 'react';

export function SonosIcon() {
  return (
    <svg
      className="icon icon--sonos icon__fill"
      width={360}
      height={72}
      viewBox="0 0 360 72"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.813 41.387L152.619 1.885V68.32h12.568V31.511l42.195 39.502V4.579h-12.569v36.808zM96.958.987C77.207.988 61.945 16.25 61.945 36c0 18.853 16.16 35.013 35.013 35.013 19.75 0 35.012-15.262 35.012-35.013 0-19.75-15.262-35.012-35.012-35.012zm0 57.457c-12.569 0-22.444-9.875-22.444-22.444 0-12.569 9.875-22.444 22.444-22.444 12.568 0 22.444 9.875 22.444 22.444 0 12.569-9.876 22.444-22.444 22.444zM40.399 36c-3.591-2.693-8.08-4.489-14.364-6.284-12.569-3.591-12.569-7.182-12.569-8.978 0-3.59 4.49-7.182 10.773-7.182 5.387 0 9.876 2.693 11.671 3.591l.898.898 9.875-7.182-.897-.898S37.706.988 24.239.988c-6.284 0-12.568 1.795-17.057 5.386C2.693 9.965 0 15.352 0 20.738c0 5.387 2.693 10.773 7.182 14.364 3.591 2.694 8.08 4.49 14.364 6.285 12.569 3.59 12.569 7.182 12.569 8.977 0 3.591-4.49 7.182-10.773 7.182-5.387 0-9.876-2.693-11.671-3.59l-.898-.899L.898 60.24l.898.898s8.08 8.978 21.546 8.978c6.284 0 12.568-1.796 17.057-5.387 4.489-3.59 7.182-8.977 7.182-14.364 0-5.386-2.693-9.875-7.182-14.364zM263.043.988C243.292.988 228.03 16.249 228.03 36c0 19.75 16.16 35.013 35.013 35.013 19.75 0 35.012-15.262 35.012-35.013 0-19.75-16.16-35.012-35.012-35.012zm0 57.456c-12.569 0-22.444-9.875-22.444-22.444 0-12.569 9.875-22.444 22.444-22.444 12.568 0 22.443 9.875 22.443 22.444 0 12.569-10.773 22.444-22.443 22.444zm56.558-23.342c3.591 2.694 8.08 4.49 14.364 6.285 12.569 3.59 12.569 7.182 12.569 8.977 0 3.591-4.489 7.182-10.773 7.182-5.387 0-9.876-2.693-11.671-3.59h-.898l-9.875 7.181.898.898s8.079 8.978 21.546 8.978c6.284 0 12.568-1.796 17.057-5.387 4.489-3.591 7.182-8.978 7.182-14.364 0-5.387-2.693-10.773-7.182-14.364-3.591-2.694-8.08-4.489-14.364-6.285-12.569-3.59-12.569-7.182-12.569-8.977 0-3.591 4.489-7.182 10.774-7.182 5.386 0 9.875 2.693 11.67 3.59l.898.899 9.875-7.182-.897-.898s-8.08-8.978-21.546-8.978c-6.285 0-12.569 1.796-17.058 5.387-4.489 3.59-7.182 8.977-7.182 14.364-.898 5.387 1.796 9.875 7.182 13.466z"
        fill="#000"
      />
    </svg>
  );
}
