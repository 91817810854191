import React, { useContext, useEffect, useState } from 'react';
import { globalHistory } from '@reach/router'
import { BackgroundContext } from './Background';
// hooks
import useMediaQuery from '../hooks/useMediaQuery';
// components
import MobileNavbar from './MobileNavbar';
import Navbar from './Navbar';
import { FalkonLogo } from './svgs';
import Swipe from '../lib/swipe';

function Header() {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const { bgColor, textColor } = useContext(BackgroundContext);
  const [isOpen, toggleNav] = useState(false);

  /**
   * Handle Nav State
   */
  const handleNavState = () => toggleNav(!isOpen);

  /**
   * Time Released Toggle
   */
  const timeReleasedReset = (ms) => {
    setTimeout(() => {
      toggleNav(false);
    }, ms)
  }

  // reset nav state on route change
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') timeReleasedReset(500);
    })
  }, [toggleNav])

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Swipe
            to="/"
            swipe
            direction="right"
            top="exit"
            entryOffset={100}
            duration={2}
            className="navbar__link"
          >
            <FalkonLogo />
          </Swipe>
        </div>
        {isDesktop ?
          (
            <Navbar
              bgColor={bgColor}
              handleNavState={handleNavState}
              isOpen={isOpen}
              textColor={textColor}
            />
          ) : (
            <MobileNavbar
              bgColor={bgColor}
              handleNavState={handleNavState}
              isOpen={isOpen}
              textColor={textColor}
            />
          )
        }
      </div>
    </header>
  );
}

export default Header;
