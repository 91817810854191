/* eslint-disable react/prop-types */
import React from 'react';
import { string } from 'prop-types';

export function TheNorthFaceIcon({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      viewBox="0 0 200 94"
    >
      <path d="M70.5 28h-5.8V5.1h-8.4v-5h22.6v5h-8.4zm16.3-12.5V28H81V.1h5.8v10.6h10.9V.1h5.8V28h-5.8V15.5zM126 5h-14.6v5.9h12.9v4.9h-12.9v7.3H126V28h-20V.1h20zM17.3 32.3h5.4v27.8h-5.8L5.5 40.3h-.1v19.8H0V32.3h6.1l11.1 19.3h.1zm21.4-.8c3.7 0 13.4 1.6 13.4 14.6s-9.7 14.6-13.4 14.6-13.4-1.6-13.4-14.6S35 31.5 38.7 31.5m0 24.4c3.1 0 7.5-1.9 7.5-9.7s-4.4-9.7-7.5-9.7-7.6 1.9-7.6 9.7 4.5 9.7 7.6 9.7m54.4 4.2h-5.8V37.2h-8.5v-4.9h22.7v4.9h-8.4zm16.3-12.5v12.5h-5.8V32.3h5.8v10.5h10.8V32.3h5.8v27.8h-5.8V47.6zM54.5 32.3h14.1c7.4 0 8.8 5.5 8.8 7.9 0 3.1-1.6 5.7-4.3 6.6 2.3 1 3.5 1.9 3.5 7.1 0 4.1 0 5.1 1.2 5.5v.7h-6.4c-.4-1.3-.6-2.7-.6-5.6 0-3.8-.2-5.3-4.5-5.3h-6v10.9h-5.7l-.1-27.8zM67 44.5c3 0 4.5-1.1 4.5-3.8 0-1.5-.7-3.6-4.1-3.6h-7.2v7.4H67zM41 93.1h-5.9V64.5h20.3v5.1H41V76h12.6v5.1H41zm17.1 0h-6.3L62 64.5h6.9L79 93.1h-6.5l-1.8-5.9H60l-1.9 5.9zm3.5-10.8h7.3L65.4 71h-.1l-3.7 11.3zM97.2 74c-.5-1.7-1.4-4.9-6.3-4.9-2.9 0-7.1 1.9-7.1 10 0 5.1 2 9.7 7.1 9.7 3.3 0 5.6-1.9 6.3-5.4h6C102 89.6 98 93.9 90.8 93.9c-7.6 0-13-5.1-13-15 0-10 5.8-15.1 13.1-15.1 8.5 0 12 5.9 12.3 10.1h-6v.1zm28.9-4.4h-15v6h13.3v5h-13.3V88h15v5.1h-20.5V64.5h20.5zm-101.8 9c4.2 0 7.7 3.3 7.7 7.7S28.5 94 24.3 94s-7.8-3.3-7.8-7.7 3.6-7.7 7.8-7.7m0 14.3c3.6 0 6.3-2.8 6.3-6.6 0-3.7-2.8-6.5-6.3-6.5-3.6 0-6.4 2.8-6.4 6.5s2.8 6.6 6.4 6.6m-3-11.1h3.5c2.1 0 3.2.8 3.2 2.6 0 1.6-1 2.3-2.4 2.5l2.6 4h-1.5L24.2 87h-1.5v3.9h-1.4v-9.1zm1.4 4h1.5c1.2 0 2.4-.1 2.4-1.5 0-1.2-1-1.4-2-1.4h-1.9v2.9zm122.4 5.5V76.6c0-6.6-5.4-12-12-12h-1.3v28.6h13.3v-1.9zm54.9 0V66.9C200 30 170 0 133.1 0h-.7.1-.7v28h1.9c21.7.3 39.4 18 39.7 39.7V93H200v-1.7z" />
      <path d="M169.1 92.3V68.1c-.1-19.3-15.5-35-34.6-35.8h-2.2.1-.7v27.9h1.3c9 0 16.2 7.3 16.2 16.2V93h19.9v-.7z" />
    </svg>
  );
}
