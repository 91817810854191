import React, { createContext, useState } from 'react';
import styled from 'styled-components';

export const BackgroundContext = createContext();

const StyledBackground = styled.main.attrs(({ bgColor, textColor }) => ({
  style: {
    backgroundColor: bgColor,
    color: textColor,
  },
}))`
  transition: background-color 500ms ease, color 500ms ease;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

function Background({ children }) {
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  return (
    <BackgroundContext.Provider
      value={{ bgColor, setBgColor, textColor, setTextColor }}
    >
      <StyledBackground bgColor={bgColor} textColor={textColor}>
        {children}
      </StyledBackground>
    </BackgroundContext.Provider>
  );
}

export default Background;
