import React from 'react';

export function CloseIcon(props) {
  // eslint-disable-next-line react/prop-types
  const { textColor } = props;
  return (
    <svg
      id="prefix__\u0421\u043B\u043E\u0439_1"
      viewBox="0 0 139 139"
      xmlSpace="preserve"
    >
      <path
        stroke={textColor}
        strokeWidth="6"
        strokeMiterlimit="10"
        className="prefix__st0"
        id="prefix__XMLID_3_"
        d="M100 38.6l-61 61"
      />
      <path
        stroke={textColor}
        strokeWidth="6"
        strokeMiterlimit="10"
        className="prefix__st0"
        id="prefix__XMLID_2_"
        d="M100 99.6l-61-61"
      />
    </svg>
  );
}
