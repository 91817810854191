import React, { useState } from 'react';
// components
import { Link } from 'gatsby';
import { CloseIcon, MenuIcon } from './svgs';
// data
import { navigation_old as navigation } from '../data/navigation.json';

function MobileNavbar(props) {
  const { isOpen, handleNavState, textColor } = props;

  return (
    <div className={isOpen ? 'nav is-open' : 'nav'}>
      <nav className="nav__container" role="navigation">
        {navigation.map(({ text, route }, index) => (
          <Link
            className="nav__link"
            to={route}
            key={index}
          >
            {text}
          </Link>
        ))}
      </nav>
      {isOpen ?
        (
          <button
            type="button"
            className="nav__button nav__button--close"
            onClick={handleNavState}
          >
            <CloseIcon textColor={textColor} className="nav__button-icon" />
          </button>
        ) : (
          <button
            type="button"
            className="nav__button"
            onClick={handleNavState}
          >
            <MenuIcon textColor={textColor} className="nav__button-icon" />
          </button>
        )}
    </div>
  );
}

export default MobileNavbar;
