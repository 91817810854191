import React from 'react';

export function FalkonLogo() {
  return (
<svg version="1.1" id="Layer_1" viewBox="0 0 1600 400" className="icon icon__fill">
<g>
    <rect x="941.68" y="225.58" class="st0" width="5.33" height="0" />
    <path class="st1" d="M432.64,270.99h-92.25L331,291.16h-57.37l84.08-181.62h57.31l84.18,181.62h-57.26L432.64,270.99z
M386.27,171.41l-25.22,54.17h50.18L386.27,171.41z"/>
    <path class="st1" d="M684.02,245.76v45.41H512.5V109.54h52.41v136.22H684.02z" />
    <path class="st1" d="M888.54,291.16h-66.7l-53.51-65.81l-17.95,18.01v47.79h-52.41V109.54h52.41v66.91l66.7-66.91h66.7
l-78.72,78.97L888.54,291.16z"/>
    <path class="st1" d="M1281.7,200.35c0,75.68-38.23,95.86-109.58,95.86H981.55c-66.81,0-109.58-20.18-109.58-95.86
c0-70.63,38-95.85,109.58-95.85h190.58C1243.47,104.5,1281.7,129.72,1281.7,200.35z M1224.53,200.35
c0-40.36-18.57-50.45-52.41-50.45H981.55c-33.46,0-52.41,10.09-52.41,50.45c0,40.36,18.95,50.45,52.41,50.45h190.58
C1205.36,250.8,1224.53,240.71,1224.53,200.35z"/>
    <path class="st1" d="M1503.65,109.54v181.62h-61.94l-90.52-107.84v107.84h-52.41V109.54h57.17l95.29,113.52V109.54H1503.65z" />
    <g>
        <g>
            <path class="st1" d="M96,291.03V109.41h205.82v45.41H158.89v22.7h131.5v45.41h-131.5v68.11H96z" />
        </g>
    </g>
</g>
</svg>
  );
}
