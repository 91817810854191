import React from 'react';
import { node } from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
// styles
import '../scss/style.scss';
// components
import Background from './Background';
import Header from './Header';

function Layout({ children }) {
  return (
    <ParallaxProvider>
      <Background>
        <Header />
        {children}
      </Background>
    </ParallaxProvider>
  );
}

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
