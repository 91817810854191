import React from 'react';

export function PlayIcon() {
  return (
    <svg
      viewBox="0 0 212 212"
      width={212}
      height={212}
      className="icon icon--play-icon"
    >
      <g id="prefix__Group_38_copy">
        <path
          id="prefix__Ellipse_9_copy"
          d="M106.21 201.43c-52.65 0-95.21-42.56-95.21-95.22C11 53.56 53.56 11 106.21 11c52.66 0 95.22 42.56 95.22 95.21 0 52.66-42.56 95.22-95.22 95.22z"
          opacity={0.749}
          fill="#231f20"
        />
        <path
          id="prefix__Ellipse_10_copy"
          d="M106.21 201.43c-52.65 0-95.21-42.56-95.21-95.22C11 53.56 53.56 11 106.21 11c52.66 0 95.22 42.56 95.22 95.21 0 52.66-42.56 95.22-95.22 95.22z"
          fill="none"
          stroke="#fff"
          strokeWidth={9.03}
        />
        <path
          id="prefix__Path_4_copy"
          d="M77.82 151.19V61.23l77.45 44.98-77.45 44.98z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
