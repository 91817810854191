/* eslint-disable react/prop-types */
import React from 'react';
import { string } from 'prop-types';

export function Arrow({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      viewBox="0 0 213.64 200"
    >
      <path d="M115.76 194.95H88.79l86-86H2.64V89.83H174.8l-86-86h26.97l95.56 95.56-95.57 95.56z" />
    </svg>
  );
}

Arrow.propTypes = {
  modifier: string,
};
