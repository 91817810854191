import React from 'react';
import { string } from 'prop-types';

export function TidalIcon({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      viewBox="0 0 365 178"
      width={365}
      height={178}
    >
      <g id="prefix__XMLID_1_">
        <path
          id="prefix__XMLID_3_"
          className="prefix__shp0"
          d="M149.9.2h.2c1.5 1.8 3.3 3.3 4.9 5l11.1 11.1v.2c-5.4 5.4-10.7 10.8-16.1 16.1l-16.2-16.2c.3-.5.8-.9 1.2-1.3 5-4.9 10-9.9 14.9-14.9z"
        />
        <path
          id="prefix__XMLID_4_"
          className="prefix__shp0"
          d="M149.9.2h.2c1.5 1.8 3.3 3.3 4.9 5l11.1 11.1v.2c-5.4 5.4-10.7 10.8-16.1 16.1l-16.2-16.2c.3-.5.8-.9 1.2-1.3 5-4.9 10-9.9 14.9-14.9-4.9 5-9.9 9.9-14.9 14.9-.4.4-.9.8-1.2 1.3L150 32.6c5.4-5.4 10.7-10.8 16.1-16.1v-.2L155 5.2c-1.6-1.7-3.4-3.2-4.9-5h-.2z"
        />
        <path
          id="prefix__XMLID_5_"
          className="prefix__shp0"
          d="M182.3.2h.2c.2.2.3.4.5.6 5.2 5.1 10.3 10.4 15.6 15.5-.1.3-.4.5-.6.7-5.2 5.2-10.4 10.4-15.6 15.5-5.4-5.4-10.8-10.8-16.2-16.1C171.5 11 177 5.7 182.3.2z"
        />
        <path
          id="prefix__XMLID_6_"
          className="prefix__shp0"
          d="M182.3.2h.2c.2.2.3.4.5.6 5.2 5.1 10.3 10.4 15.6 15.5-.1.3-.4.5-.6.7-5.2 5.2-10.4 10.4-15.6 15.5-5.4-5.4-10.8-10.8-16.2-16.1C171.5 11 177 5.7 182.3.2 177 5.7 171.4 11 166.2 16.5c5.5 5.3 10.8 10.8 16.2 16.1 5.2-5.2 10.4-10.4 15.6-15.5.2-.2.5-.4.6-.7-5.2-5.1-10.4-10.3-15.6-15.5-.2-.2-.3-.4-.5-.6l-.2-.1z"
        />
        <path
          id="prefix__XMLID_7_"
          className="prefix__shp0"
          d="M214.7.2h.2c1 1.2 2.2 2.2 3.3 3.4l12.2 12.2c.2.2.5.4.6.8-5.5 5.3-10.7 10.8-16.2 16.1l-15.4-15.4c-.3-.3-.6-.5-.7-.8l8.1-8.1c2.6-2.8 5.3-5.5 7.9-8.2z"
        />
        <path
          id="prefix__XMLID_8_"
          className="prefix__shp0"
          d="M214.7.2h.2c1 1.2 2.2 2.2 3.3 3.4l12.2 12.2c.2.2.5.4.6.7-5.5 5.3-10.7 10.8-16.2 16.1l-15.4-15.4c-.3-.3-.6-.5-.7-.8l8.1-8.1c2.6-2.7 5.3-5.3 7.9-8.1-2.6 2.7-5.3 5.3-8 8l-8.1 8.1c.1.4.5.6.7.8l15.4 15.4c5.5-5.3 10.8-10.8 16.2-16.1-.1-.3-.4-.5-.6-.8L218.1 3.4c-1.1-1.1-2.3-2.2-3.3-3.4l-.1.2z"
        />
        <path
          id="prefix__XMLID_9_"
          className="prefix__shp0"
          d="M166.4 48.6l15.9-15.9.2.1c5.3 5.4 10.7 10.7 16 16-.2.4-.6.6-.8 1-3.9 3.8-7.7 7.7-11.6 11.6-1.3 1.2-2.5 2.6-3.7 3.7-1.8-1.7-3.5-3.5-5.3-5.2-3.6-3.6-7.2-7.2-10.9-10.8.1-.3.2-.4.2-.5z"
        />
        <path
          id="prefix__XMLID_10_"
          className="prefix__shp0"
          d="M166.4 48.6l15.9-15.9.2.1c5.3 5.4 10.7 10.7 16 16-.2.4-.5.6-.8 1-3.9 3.8-7.7 7.7-11.6 11.6-1.3 1.2-2.5 2.6-3.7 3.7-1.8-1.7-3.5-3.5-5.3-5.2-3.6-3.6-7.2-7.2-10.9-10.8.1-.3.2-.4.2-.5-.1.1-.1.2-.2.3 3.6 3.6 7.2 7.2 10.9 10.8 1.8 1.7 3.5 3.6 5.3 5.2 1.3-1.1 2.5-2.5 3.7-3.7 3.9-3.8 7.7-7.7 11.6-11.6.3-.3.7-.6.8-1-5.4-5.3-10.7-10.7-16-16l-.2-.1c-5.3 5.5-10.6 10.8-15.9 16.1z"
        />
        <path
          id="prefix__XMLID_11_"
          className="prefix__shp0"
          d="M0 118.2h48.3v11.6H30.9v45.7c0 .8.1 1.5-.1 2.3H17.4c.1-16 0-32 0-48H0v-11.6z"
        />
        <path
          id="prefix__XMLID_12_"
          className="prefix__shp0"
          d="M0 118.2h48.3v11.6H30.9v46.7c0 .4 0 .8-.1 1.3H17.4v-24-24H0v-11.6 11.6h17.4v48h13.5c.1-.4.1-.8.1-1.3v-.6-.4-45.7h17.4V124v-5.8H0z"
        />
        <path
          id="prefix__XMLID_13_"
          className="prefix__shp0"
          d="M88.7 118.2h13.6c0 19.8.1 39.7-.1 59.5H88.7c-.1-5.3 0-10.5-.1-15.8.1-14.5 0-29.1.1-43.7z"
        />
        <path
          id="prefix__XMLID_14_"
          className="prefix__shp0"
          d="M88.7 118.2h13.6v33.7c0 8.6 0 17.2-.1 25.8H88.7v-7.9-4-4-21.9-21.7V177.9h13.5c0-8.6.1-17.2.1-25.8v-24.2-9.5c-4.6-.2-9.1-.2-13.6-.2z"
        />
        <path
          id="prefix__XMLID_15_"
          className="prefix__shp0"
          d="M327.8 118.2h13.6v47.6H365v11.9h-37.2v-59.5z"
        />
        <path
          id="prefix__XMLID_16_"
          className="prefix__shp0"
          d="M327.8 118.2h13.6v47.6H365v11.9h-37.1v-21.1-29.2c-.1-3-.1-6.1-.1-9.2v59.5H365v-12h-23.6v-47.6c-4.5.1-9.1.1-13.6.1z"
        />
        <path
          id="prefix__XMLID_19_"
          fillRule="evenodd"
          className="prefix__shp0"
          d="M201.4 152.4c-.7 5.2-2.8 10.3-6.2 14.3-4.6 5.5-11.4 9-18.4 10.3-2.1.4-4.2.4-6.3.8h-23.3c0-.1.1-.4.1-.5-.1-19.7 0-39.3-.1-59 .3-.1.6-.1.9-.1h16.1c3.7 0 7.3-.1 10.9.4 6.3.9 12.7 3.2 17.5 7.5 3.7 3.2 6.3 7.4 7.7 12.1 1.3 4.6 1.6 9.5 1.1 14.2zm-13.9-6.3c-.3-4.2-2-8.4-5-11.3-3.4-3.3-8.1-4.9-12.8-5h-9.3v26.1c.1 3.3-.1 6.7.1 10h9c2.9 0 5.8-.6 8.4-1.8 2.9-1.3 5.4-3.4 7.1-6.1 2.2-3.5 2.9-7.8 2.5-11.9z"
        />
        <path
          id="prefix__XMLID_22_"
          fillRule="evenodd"
          className="prefix__shp0"
          d="M192.6 126.1c-4.9-4.3-11.2-6.6-17.5-7.5-2.4-.4-4.9-.4-7.4-.4h.1c2.5 0 4.9 0 7.4.4 6.4.8 12.7 3.2 17.5 7.5 1.32 1.17 2.5 2.48 3.54 3.89a25.747 25.747 0 00-3.64-3.89zm-44.4-7.9c-.3 0-.6 0-.9.1v59c0 .1-.1.4-.1.5h-.1c0-.1.1-.4.1-.5v-17.5-18.6-22.9c.3-.1.6-.1.9-.1h.1zm22.2 59.6c.49-.09.98-.16 1.46-.21-.45.06-.91.12-1.36.21h-.1zm5.9-.71c.14-.03.27-.06.4-.09 7.1-1.3 13.8-4.8 18.4-10.3 3.4-4.1 5.5-9.1 6.2-14.3.2-1.6.3-3.2.3-4.8 0-3.2-.4-6.4-1.3-9.4.9 3 1.3 6.2 1.4 9.4 0 1.6-.1 3.2-.3 4.8-.7 5.2-2.8 10.3-6.2 14.3-4.6 5.5-11.4 9-18.4 10.3l-.5.09zm19.94-47.1a24.36 24.36 0 014.06 8.21 26.81 26.81 0 00-4.06-8.21zm-24.38 47.6c1.48-.19 2.96-.26 4.44-.5-1.48.3-2.96.34-4.44.5zM185 158c-1.7 2.7-4.2 4.8-7 6.2-2.6 1.2-5.5 1.7-8.4 1.8h-9c0-.03 0-.07-.01-.1h8.91c2.9 0 5.8-.6 8.4-1.8 2.9-1.3 5.4-3.4 7.1-6.1zm-24.5 7.9c-.1-1.4-.1-2.7-.1-4.1v-4-1.9-26.1h9.3c4.7.1 9.4 1.7 12.8 5a16.101 16.101 0 01.1.1c-3.4-3.3-8.1-4.9-12.8-5h-9.3v32c0 1.37 0 2.64.09 4h-.09zm23.79-29.02c1.95 2.71 3.07 5.98 3.31 9.32.1.5.1 1.1.1 1.6 0 3.5-.8 7.1-2.7 10.2 1.9-3.1 2.7-6.7 2.6-10.3 0-.5-.1-1.1-.1-1.6-.23-3.28-1.32-6.56-3.21-9.22z"
        />
        <path
          id="prefix__XMLID_25_"
          fillRule="evenodd"
          className="prefix__shp0"
          d="M291.6 177.7h-14.9c-1.3-3.8-2.7-7.5-4.1-11.3-.1-.3-.3-.7-.7-.6-8.2.1-16.4 0-24.6.1-1.4 4-3 7.9-4.4 11.9h-14.4c.1-.7.4-1.3.7-1.9l24-57.6c4.7-.1 9.3-.1 14 0 .6 1.3 1.2 2.7 1.7 4.1 5.8 13.8 11.4 27.6 17.1 41.4 1.9 4.7 3.9 9.3 5.6 13.9zm-22.8-22.4v-.4c-2.9-7.6-5.8-15.1-8.6-22.6 0 0-.1.1-.2.1-2.7 7.2-5.6 14.3-8.3 21.5-.1.5-.5 1-.4 1.5 5.8-.1 11.6-.1 17.5-.1z"
        />
        <path
          id="prefix__XMLID_28_"
          fillRule="evenodd"
          className="prefix__shp0"
          d="M267.3 116.3c-2.3 0-4.7-.1-7-.1 2.3 0 4.7 0 7 .1zM243 175.8c1.4-4 3-7.9 4.4-11.9 3.3-.1 6.7-.1 10-.1h.1c-3.3 0-6.7 0-10 .1-1.4 4-3 7.9-4.4 11.9h-.1zm29.3-12c.3 0 .4.4.5.6 1.4 3.8 2.8 7.5 4.1 11.3h14.76c.01.03.03.07.04.1h-14.9c-1.4-3.8-2.8-7.5-4.1-11.3-.1-.3-.2-.7-.5-.7h.1zm19.36 11.9c-1.79-4.67-3.77-9.23-5.66-13.9 1.9 4.6 3.9 9.3 5.8 13.9h-.14zm-38.36-59.4c2.3-.1 4.7-.1 7-.1-2.3 0-4.7.1-7 .1zm-2 37.1l-.1-.1c0-.5.3-.9.4-1.3 2.8-7.2 5.6-14.3 8.3-21.5 0-.06.04-.09.1-.1-2.7 7.2-5.6 14.3-8.3 21.5-.1.4-.4.8-.4 1.3v.2zm8.7-23h.2c2.8 7.5 5.7 15 8.6 22.6v.4h-.1v-.4c-2.9-7.5-5.8-15-8.6-22.6h-.1zm.1 23H251.3c2.9-.1 5.8-.1 8.8 0zm0 0h8.6-8.6z"
        />
      </g>
    </svg>
  );
}

TidalIcon.propTypes = {
  modifier: string,
};
