import React from 'react';
import { string } from 'prop-types';

export function LionsgateIcon({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      height={124.247}
      viewBox="0 0 1000 124.247"
      width={1000}
    >
      <path d="M775.563 3.799v23.684h35.481v92.994h25.677V27.483h35.474V3.799zM379.348 3.799v71.848L324.693 3.799h-23.654v116.678h25.322V46.309l56.489 74.168h21.834V3.799zM599.083 78.955h33.784c-1.573 8.86-10.275 21.913-32.022 21.913-21.319 0-38.477-16.954-38.477-38.606 0-22.074 16.693-38.738 38.883-38.738 22.349 0 32.682 17.665 33.117 18.419l19.898-12.328C642.591 10.819 623.273.014 601.25.014c-34.858 0-63.226 27.802-63.226 61.986 0 34.894 27.403 62.247 62.392 62.247 32.45 0 57.859-23.306 57.859-54.821v-13.56h-59.193v23.089zM490.1 53.531c-16.824-6.309-33.43-8.47-33.43-19.71 0-6.526 6.787-11.414 21.146-11.414 13.814 0 29.456 6.294 39.919 11.82V9.354C503.616 3.48 490.114 0 476.169 0c-28.186 0-47.258 14.271-47.258 34.981 0 22.61 19.282 30.602 32.704 35.634 16.809 6.279 32.937 8.586 32.937 19.825 0 6.512-7.752 11.399-22.095 11.414-13.408 0-29.688-6.323-40.145-11.834v24.974c14.619 6.251 27.969 9.253 41.761 9.253 28.484.016 48.208-14.271 48.208-34.98.001-22.612-18.752-30.689-32.181-35.736M216.008 0c-33.93 0-61.522 27.584-61.522 61.522 0 34.532 27.28 62.595 60.811 62.595 35.032 0 62.479-27.426 62.479-62.465C277.776 27.671 250.061 0 216.008 0m-.291 99.824c-20.543 0-36.656-16.592-36.656-37.766 0-21.087 16.337-37.606 37.2-37.606 20.645 0 36.787 16.417 36.787 37.331 0 21.682-16.026 38.041-37.331 38.041M25.663 3.814H0v116.648h83.631v-23.32H25.663zM971.374 97.634h-54.509V73.139h49.948c-.341-4.09-.565-8.237-.565-12.429 0-3.524.159-6.976.449-10.399h-49.832V26.643h53.914a131.52 131.52 0 018.564-22.828v-.016h-87.96v116.677h88.765a133.144 133.144 0 01-8.774-22.842M104.646 3.814h25.663v116.662h-25.663zM713.091 3.799l-49.637 116.663h26.171l9.281-22.958h52.066l9.282 22.958h26.852L737.448 3.829zm-5.483 72.168l17.317-42.16 17.439 42.16z" />
      <path
        clipRule="evenodd"
        d="M991.8 7.344c1.11 0 1.909.117 2.415.351.906.389 1.358 1.081 1.368 2.22 0 .789-.272 1.354-.871 1.724-.298.195-.726.36-1.267.419.672.117 1.169.409 1.5.886.326.467.487.896.487 1.354V15.623c.029.224.038.37.121.448l.049.098h-1.466s0-.02-.02-.068c0-.029 0-.029-.033-.107l-.02-.283V15c0-1.003-.288-1.675-.838-2.016-.316-.195-.906-.253-1.724-.292h-1.237v3.477h-1.533V7.344zm1.655 1.403c-.365-.204-.993-.341-1.855-.341h-1.334v3.224h1.427c.628 0 1.125-.078 1.446-.214.609-.233.916-.691.926-1.393-.001-.594-.201-1.043-.61-1.276m4.202 8.726c-1.587 1.597-3.472 2.376-5.706 2.376-2.226 0-4.101-.779-5.674-2.376-1.548-1.539-2.313-3.438-2.313-5.697 0-2.181.765-4.061 2.322-5.639 1.563-1.568 3.438-2.318 5.664-2.337 2.225 0 4.119.75 5.706 2.337 1.544 1.559 2.333 3.428 2.343 5.639.001 2.24-.769 4.099-2.342 5.697M987.086 6.926c-1.339 1.314-1.996 2.97-1.996 4.85 0 1.928.657 3.574 1.996 4.928 1.314 1.383 2.961 2.036 4.865 2.036 1.933 0 3.549-.652 4.908-2.036 1.344-1.354 1.981-3 1.981-4.928 0-1.879-.638-3.496-1.981-4.85-1.359-1.412-2.976-2.045-4.908-2.045-1.885-.001-3.502.633-4.865 2.045"
        fillRule="evenodd"
      />
    </svg>
  );
}

LionsgateIcon.propTypes = {
  modifier: string,
};
