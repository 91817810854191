import React from 'react';

export function PlayIcon2() {
  return (
    <svg
      viewBox="0 0 212 212"
      width={212}
      height={212}
      className="icon icon--play-icon"
    >
      <path d="M77.82 151.19V61.23l77.45 44.98-77.45 44.98z" fill="#fff" />
    </svg>
  );
}
