import React from 'react';
import { string } from 'prop-types';

export function ItunesIcon({ modifier }) {
  return (
    <svg
      className={modifier ? `icon icon__fill ${modifier}` : 'icon icon__fill'}
      height={69.39}
      width={240}
      viewBox="7104.335 7172.337 191.331 55.328"
    >
      <path d="M7151.891 7212.926c-1.299 2.881-1.922 4.166-3.595 6.711-2.334 3.556-5.625 7.979-9.703 8.019-3.625.033-4.557-2.357-9.475-2.332-4.917.026-5.943 2.373-9.566 2.34-4.078-.037-7.197-4.033-9.533-7.586-6.528-9.938-7.211-21.6-3.186-27.799 2.861-4.404 7.376-6.982 11.622-6.982 4.323 0 7.04 2.369 10.612 2.369 3.467 0 5.578-2.373 10.576-2.373 3.776 0 7.778 2.059 10.631 5.609-9.342 5.119-7.823 18.456 1.617 22.024z" />
      <path d="M7135.854 7181.319c1.815-2.33 3.194-5.619 2.694-8.982-2.967.205-6.437 2.092-8.461 4.551-1.838 2.232-3.355 5.543-2.766 8.762 3.239.099 6.589-1.835 8.533-4.331zM7168.464 7197.131c0 2.019-1.535 3.647-3.938 3.647-2.305 0-3.84-1.631-3.792-3.647-.048-2.11 1.487-3.694 3.84-3.694s3.843 1.584 3.89 3.694zm-7.536 30.004v-23.476h7.297v23.476zm19.632-26.211h-8.688v-6.144h24.865v6.144h-8.832v26.211h-7.347zm39.361 18.675c0 3.071.097 5.567.19 7.537h-6.336l-.336-3.312h-.146c-.912 1.439-3.121 3.842-7.346 3.842-4.753 0-8.257-2.979-8.257-10.227v-13.775h7.345v12.625c0 3.408 1.104 5.471 3.648 5.471 2.018 0 3.169-1.391 3.648-2.543.19-.432.237-1.008.237-1.584v-13.969h7.347v15.936h.006zm5.854-8.452c0-2.93-.096-5.426-.191-7.487h6.336l.336 3.217h.146c.959-1.487 3.357-3.744 7.248-3.744 4.801 0 8.398 3.168 8.398 10.08v13.922h-7.295v-13.01c0-3.022-1.057-5.088-3.695-5.088-2.018 0-3.217 1.394-3.695 2.733-.191.48-.287 1.152-.287 1.824v13.537h-7.297v-15.983zm33.745 6.579c.24 3.024 3.217 4.465 6.625 4.465 2.496 0 4.513-.336 6.479-.959l.961 4.942c-2.399.961-5.328 1.487-8.498 1.487-7.97 0-12.528-4.655-12.528-12.002 0-5.953 3.696-12.526 11.856-12.526 7.586 0 10.465 5.901 10.465 11.713 0 1.248-.145 2.353-.238 2.879zm8.593-4.995c0-1.774-.77-4.752-4.129-4.752-3.072 0-4.32 2.783-4.514 4.752zm11.086 8.019c1.347.813 4.129 1.774 6.289 1.774 2.209 0 3.121-.77 3.121-1.969s-.721-1.775-3.457-2.688c-4.849-1.631-6.721-4.271-6.672-7.008 0-4.416 3.744-7.73 9.554-7.73 2.735 0 5.187.625 6.625 1.346l-1.297 5.041c-1.058-.576-3.071-1.346-5.088-1.346-1.774 0-2.784.721-2.784 1.92 0 1.105.912 1.682 3.792 2.689 4.465 1.535 6.337 3.793 6.385 7.248 0 4.369-3.456 7.633-10.177 7.633-3.07 0-5.811-.722-7.585-1.683z" />
    </svg>
  );
}

ItunesIcon.propTypes = {
  modifier: string,
};
